@import url("https://fonts.googleapis.com/css2?family=Jomhuria&display=swap");
@import url("http://fonts.googleapis.com/earlyaccess/droidarabickufi.css");
@import url("https://fonts.googleapis.com/earlyaccess/notonaskharabic.css");

.App {
  text-align: center;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.wave {
  transform: rotate(180deg);
  left: 0;
}

.wave1 {
  transform: rotate(180deg);
  left: 0;
  height: 45vh;
}

.prayers {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  padding: 1rem 3rem;
  .prayer-btn {
    background-color: #fff;
    font-weight: bold;
    color: black;
    display: flex;
    justify-content: space-between;
    padding: 1rem 3rem;
    margin: 0.4rem 0rem;
    animation: gradient 15s ease infinite;
  }
  .prayers-heading {
    margin-top: 1rem;
    display: flex;
    padding: 0rem 1.7rem;
    justify-content: space-between;
    font-size: 1.3rem;
    color: #3ba59a;
    font-weight: bold;
    p {
      margin: 0%;
    }
  }
}

.al-quran {
  display: flex;
  flex: 1;
  flex-direction: column;
  .search {
    height: 30vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    position: absolute;
    left: 0;
    right: 0;
    input {
      width: 90%;
      margin: 0rem 0.5rem;
      height: 2.5rem;
      border: 0px solid #fff;
      border-radius: 20px 20px;
      font-size: 1.1rem;
      color: rgba(0, 0, 0, 0.76);
    }
    input:focus {
      outline: none;
    }
  }

  .al-quran-surahs {
    margin-top: 1rem;
    padding: 0rem 2rem;
    margin-bottom: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 1rem;
    .button {
      width: 25rem;
      min-height: 6rem;
      max-height: 6rem;
      background-color: #fff;
      border: 2px solid gray;
      border-radius: 1rem;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-overflow: clip;
      font-size: 1rem;
      color: gray;
      white-space: wrap;
      .surah-name {
        text-align: start;
        font-size: 1rem;
        line-height: 1.5rem;
      }
      .arabic-name {
        text-align: center;
        h4 {
          font-family: "Arabic";
          font-size: 1rem;
          font-weight: 400;
        }
        p {
          font-weight: 600;
        }
      }
    }
    button:hover {
      border: 2px solid #41c1c3;
      color: #41c1c3;
      #number {
        background: #41c1c3;
      }
    }
    #number {
      padding: 0px 7px;
      background: gray;
      border-radius: 50%;
      height: 2rem;
      width: 1.4rem;
      display: grid;
      place-items: center;
      font-size: 1rem;
      color: #fff;
      margin: 0;
    }
  }
  .juz-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 1rem;
  }
}

.juz-button {
  max-width: 25rem;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem 0.4rem;
  p {
    text-align: start;
    font-weight: 400;
  }
  .button1 {
    width: 23rem;
    min-height: 6rem;
    max-height: 6rem;
    background-color: #fff;
    border: 2px solid gray;
    border-radius: 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-overflow: clip;
    font-size: 1rem;
    color: gray;
    white-space: wrap;
    padding: 0rem 0.5rem;
    .surah-name {
      text-align: start;
      font-size: 1rem;
      line-height: 1.5rem;
    }
    .arabic-name {
      text-align: center;
      h4 {
        font-family: "Arabic";
        font-size: 1rem;
        font-weight: 400;
      }
      p {
        font-weight: 600;
        text-align: center;
      }
    }
  }
  button:hover {
    border: 2px solid #41c1c3;
    color: #41c1c3;
    #number {
      background: #41c1c3;
    }
    background: transparent;
  }
}

#islamic-date {
  color: #3ba59a;
}

.surah {
  display: flex;
  flex-direction: column;
  .headings {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    min-height: 20vh;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 1rem 0rem;
    gap: 1rem;
  }
  h1 {
    color: #fff;
    margin: 0%;
    font-family: "Noto Naskh Arabic";
  }
  .ayahs {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 1rem 1rem;
    gap: 1rem;
    .arabic {
      direction: rtl;
      white-space: wrap;
    }
    .urdu {
      font-family: "Noto Naskh Arabic";
    }
    #halfbold {
      font-size: 1.7rem;
      font-weight: 600;
      margin: 1rem;
    }
  }
  .ayahs-juz {
    display: flex;
    justify-content: flex-start;
    padding: 1rem 1rem;
    gap: 1rem;
    flex-wrap: wrap;
    direction: rtl;
    text-align: center;
    flex-direction: column;
    .arabic {
      white-space: wrap;
      width: fit-content;
    }
    .urdu {
      font-family: "Noto Naskh Arabic";
    }
    #halfbold {
      font-size: 1.7rem;
      font-weight: 600;
      margin: 1rem;
    }
  }
  #bold {
    font-size: 1.8rem;
    font-weight: bold;
    text-align: center;
    font-family: "Noto Naskh Arabic";
    color: #22c1c3;
  }
}

#arabic {
  display: flex;
  direction: rtl;
  font-family: "Noto Naskh Arabic";
  padding: 0rem 1rem;
  text-align: right;
  font-size: 1.1rem;
  font-weight: bold;
  p {
    margin: 0.5rem 0rem;
    color: rgb(51, 51, 51);
  }
  span {
    min-width: 3rem;
    font-size: 0.7rem;
    padding: 0rem 0.5rem;
    border-radius: 50px;
    border: 1px solid black;
  }
}
.prayer-calender {
  display: flex;
  align-items: center;
  flex: 1;
  flex-direction: column;
  margin-top: 1rem;
  .inputs {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    flex-wrap: wrap;
    div {
      display: flex;
      align-items: center;
      min-width: 10rem;
      gap: 1rem;
      .select {
        width: 10rem;
        height: 2.3rem;
        background-color: #22c1c3;
        margin: 1% 0%;
        padding: 0% 1%;
        font-size: 0.8rem;
        font-weight: bold;
        cursor: pointer;
        border-radius: 5px;
        color: #fff;
        border: none;
        option {
          cursor: pointer;
          font-size: 0.8rem;
          font-weight: 400;
          width: 100%;
          background-color: #fff;
          color: gray;
        }
      }
      select:focus {
        outline: none;
      }
    }
  }
  .table-header {
    display: flex;
    justify-content: space-between;
    background-color: #22c1c3;
    width: 100%;
    div {
      flex: 1;
      text-align: left;
      padding: 0rem 0.5rem;
      color: #fff;
    }
  }
  .table-row {
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    width: 100%;
    div {
      flex: 1;
      text-align: left;
      padding: 0rem 0.5rem;
      color: black;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

.clock {
  h1 {
    font-size: 3rem;
    color: #22c1c3;
  }
  h2 {
    font-size: 1.9rem;
    color: #fec265;
  }
  h4 {
    font-size: 1rem;
    span {
      color: #22c1c3;
    }
  }
}
.footer_dev__info {
  font-size: 18px;
  margin: 0 7px;
  font-weight: 500;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.9);
  &:hover {
    color: rgba(63, 63, 247, 0.959);
  }
}
.vertical_line {
  border-right: 2px solid rgba(255, 255, 255, 0.6);
  height: 17px;
}
.footer_privacy {
  color: rgba(255, 255, 255, 0.6);
  font-weight: 550;
  cursor: pointer;
  text-decoration: underline;
  &:hover {
    color: #fff;
  }
}

.surah-header {
  display: flex;
  flex-direction: column;
  .headings {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    min-height: 20vh;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 1rem 0rem;
    gap: 1rem;
  }
}

.privacy_policy_heading,
.about_heading {
  font-size: 40px;
  padding: 5% 0;
  background-color: #f5f5f5;
  letter-spacing: 1px;
  text-align: center;
}
@media only screen and (max-width: 768px) {
  .privacy_policy_heading,
  .about_heading {
    font-size: 30px;
    padding: 10% 0;
  }
}
.privacy_policy_content,
.about_content {
  font-size: 20px;
  margin: auto;
  margin-top: 1%;
  line-height: 40px;
  text-align: justify;
  .privacy_policy_content2 {
    margin-top: 1rem;
  }
}
.table {
  margin: 2rem auto;
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid rgba(102, 102, 102, 0.589);
    text-align: left;
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: #dddddd;
  }
}
