/* MOBILE */
html {
  font-size: 12px;
}

/* TABLET */
@media only screen and (min-width: 480px) {
  html {
    font-size: 12px;
  }
}

/* DESKTOP */
@media only screen and (min-width: 769px) {
  html {
    font-size: 16px;
  }
}

/* X-LARGE */
@media only screen and (min-width: 1024px) {
  html {
    font-size: 16px;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: darkgray;
}

* {
  margin: 0;
  padding: 0;
}
